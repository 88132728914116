import React, { useEffect, useState } from "react";
import "./Holiday.css";
import { useDispatch, useSelector } from "react-redux";
import { Api, BaseUrl, ImagePath } from "../../Config/Api";
import { useAuth } from "../../Component/Authentication/AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import { setEmployeeHoliday } from "../../Redux/Action";

const Holiday = () => {
  const getEmployeeHoliday = useSelector((state) => state.getEmployeeHoliday);
  const token = sessionStorage.getItem("authToken");
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen((prev) => !prev);
  };
  const dispatch = useDispatch();
  const { setLoading, logout } = useAuth();
  useEffect(() => {
    getHolidayList();
  }, []);
  const getHolidayList = async () => {
    try {
      const responseData = await axios.get(
        `${BaseUrl}${Api.GET_HOLIDAY}?login_token=${token}`
      );

      if (responseData?.data?.valid === false) {
        setLoading(false);
        toast.error(responseData?.data?.mssg[0], {
          position: "top-center",
          autoClose: 1000,
        });
      } else {
        if (
          responseData?.data?.mssg ===
          "unothorized token please go to login page"
        ) {
          setLoading(false);
          logout();
        } else {
          dispatch(setEmployeeHoliday(responseData?.data?.data));

          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("API call failed:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="holiday_Container ">
      <h2 className="h2">Holiday Details</h2>
      <div
        style={{
          marginTop: 20,
          justifyContent: "center",
          display: "flex",
          cursor: "pointer",
        //   position: isFullScreen ? "fixed" : "relative", // Ensure fixed position in fullscreen
          top: isFullScreen ? 0 : "auto",
          left: isFullScreen ? 0 : "auto",
          width: isFullScreen ? "100vw" : "auto",
          height: isFullScreen ? "100vh" : "auto",
          zIndex: isFullScreen ? 1000 : "auto",
          backgroundColor: isFullScreen ? "rgba(0, 0, 0, 0.9)" : "transparent",
        }}
        onClick={toggleFullScreen}
      >
        <img
          src={ImagePath + getEmployeeHoliday[0]?.image} // Ensure image URL is valid
          style={{
            maxWidth: "100%",
            height: isFullScreen ? "100vh" : "700px", // Fullscreen height
            objectFit: isFullScreen ? "cover" : "fill", // Adjust object fit for fullscreen
          }}
          alt="Preview"
        />
      </div>
    </div>
  );
};

export default Holiday;
