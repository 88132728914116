import React, { useCallback, useEffect, useState } from "react";
import "./Dashbord.css";
import backgroundImage from "../../Assets/empback.jpg";
import { useSelector } from "react-redux";

import Animation from "../../Assets/Animation1.json";
import axios from "axios";
import { Api, BaseUrl, ImagePath } from "../../Config/Api";
import { toast } from "react-toastify";
import { useAuth } from "../../Component/Authentication/AuthContext";

const Dashbord = () => {
  const employeeId = sessionStorage.getItem("employeeId");
  const token = sessionStorage.getItem("authToken");
  const getEmployeeHoliday = useSelector((state) => state.getEmployeeHoliday);
  const { setLoading, logout } = useAuth();
  const [profileData, setProfileData] = useState();
  const fetchEmployeProfile = useCallback(async () => {
    setLoading(true);

    try {
      const responseData = await axios.get(
        `${BaseUrl}${Api.GET_EMPLOYEE_PROFILE}?login_token=${token}&employee_id=${employeeId}`
      );
      if (responseData?.data?.valid === false) {
        setLoading(false);
        toast.error(responseData?.data?.mssg[0], {
          position: "top-center",
          autoClose: 1000,
        });
      } else {
        if (
          responseData?.data?.mssg ===
          "unothorized token please go to login page"
        ) {
          setLoading(false);
          logout();
        } else {
          setProfileData(responseData?.data?.data);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("API call failed:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [token, setLoading, logout]);

  useEffect(() => {
    fetchEmployeProfile();
  }, []);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="mainDiv1">
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "300px",
        }}
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "20px",
          marginTop: -120,
          // position: "absolute",

          width: "100%",
        }}
      >
        <div style={{ flex: "0 0 200px" }}>
          <img
            src={ImagePath + profileData?.image}
            alt={`${profileData?.name} profile`}
            style={{
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </div>

        <div style={{ flex: "1", marginLeft: "20px", marginTop: -20 }}>
          <h3
            style={{
              margin: "0 0 10px 0",
              fontSize: 30,
              color: "white",
              fontWeight: "700",
            }}
          >
            {profileData?.name}
          </h3>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          marginTop: -70,
          padding: "20px",
          background: "white",
          zIndex: -1,
          borderRadius: "8px", // Rounded corners for the card
        }}
      >
        <h1
          style={{
            fontSize: "24px",
            marginBottom: "20px",
            color: "black",
            marginTop: 50,
            alignItems: "center",
          }}
        >
          Profile Details
        </h1>

        {console.log("ffffffff", profileData)}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ flex: "1" }}>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Email:</strong>{" "}
              <span style={{ color: "black" }}>{profileData?.email}</span>
            </p>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Employee ID:</strong>{" "}
              <span style={{ color: "black" }}>
                {profileData?.employee_code}
              </span>
            </p>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Blood Group:</strong>{" "}
              <span style={{ color: "black" }}>{profileData?.blood_group}</span>
            </p>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>DOB:</strong>{" "}
              <span style={{ color: "black" }}>{profileData?.dob}</span>
            </p>
          </div>

          {/* Second Column */}
          <div style={{ flex: "1", paddingLeft: "10px" }}>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Department:</strong>{" "}
              <span style={{ color: "black" }}>{profileData?.department}</span>
            </p>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Designation:</strong>{" "}
              <span style={{ color: "black" }}>{profileData?.designation}</span>
            </p>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Experience:</strong>{" "}
              <span style={{ color: "black" }}>{profileData?.experience}</span>
            </p>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Date of Joining:</strong>{" "}
              <span style={{ color: "black" }}>{profileData?.doj}</span>
            </p>
          </div>
          <div style={{ flex: "1", paddingLeft: "10px" }}>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Reporting Manager:</strong>{" "}
              <span style={{ color: "black" }}>
                {profileData?.reporting_manager}
              </span>
            </p>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Emp. Status:</strong>{" "}
              <span style={{ color: "black" }}>{profileData?.status}</span>
            </p>

            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Biometric Code:</strong>
              <span style={{ color: "black" }}>{profileData?.biometrics}</span>
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: 20,
          padding: "20px",
          background: "white",
          zIndex: -1,
          borderRadius: "8px", // Rounded corners for the card
        }}
      >
        <h1
          style={{
            fontSize: "24px",
            marginBottom: "20px",
            color: "black",

            alignItems: "center",
          }}
        >
          CONTACT DETAILS
        </h1>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ flex: "1" }}>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Email:</strong>{" "}
              <span style={{ color: "black" }}>{profileData?.email}</span>
            </p>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Mobile No.:</strong>{" "}
              <span style={{ color: "black" }}>{profileData?.mobile}</span>
            </p>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Location:</strong>{" "}
              <span style={{ color: "black" }}>{profileData?.location}</span>
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: 20,
          padding: "20px",
          background: "white",
          zIndex: -1,
          borderRadius: "8px", // Rounded corners for the card
        }}
      >
        <h1
          style={{
            fontSize: "24px",
            marginBottom: "20px",
            color: "black",

            alignItems: "center",
          }}
        >
          ABOUT ME
        </h1>
        {/* <Lottie options={defaultOptions}
              height={200}
              width={200}
          

              /> */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ flex: "1" }}>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Departments:</strong>
              <span style={{ color: "black" }}>
                {" "}
                I am a professional {profileData?.designation}{" "}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashbord;
