import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BaseUrl, Api } from "../../Config/Api";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setEmployeeAllDetails, setEmployeeAuth } from "../../Redux/Action";
import { persistor } from "../../Redux/Store";
import { isMobile, isTablet } from "react-device-detect";

const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const getEmployeeAuth = useSelector((state) => state.getEmployeeAuth);
  const getEmployeeAllDetails = useSelector(
    (state) => state.getEmployeeAllDetails
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem("authToken");

    if (token) {
      dispatch(setEmployeeAuth(true));
      //  setIsAuthenticated(true);
    } else {
      navigate("/login");
    }
    setLoading(false);
  }, [navigate, dispatch]);

  const login = async (email, password, selection) => {
    setLoading(true);
    if (isMobile || isTablet) {
      toast.error("Login Not Allow In Tab Or Mobile", {
        position: "top-center",
        autoClose: 1500,
      });
      setLoading(false);
    } else {
      try {
        const data = await axios(`${BaseUrl}${Api.LOGIN}`, {
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            email: email,
            password: password,
            type: selection,
          },
        });

        if (data?.data?.valid === false) {
          setLoading(false);
          toast.error(data?.data?.mssg[0], {
            position: "top-center",
            autoClose: 1000,
          });
        } else {
          if (data?.data?.mssg === "Login successfully") {
            // setLoading(false);
            toast.success(data?.data?.mssg, {
              position: "top-center",
              autoClose: 1000,
            });
            dispatch(setEmployeeAllDetails(data?.data));
            if (data?.data?.type === "Employee") {
              const token = data?.data?.token;
              sessionStorage.setItem("authToken", token);
              console.log("Rakesh Rao", data?.data?.token);
              sessionStorage.setItem("employeeId", data?.data?.id);
              dispatch(setEmployeeAuth(true));
              navigate("/home/dashbord");
            } else {
              const token = data?.data?.token;
              sessionStorage.setItem("authToken", token);
              dispatch(setEmployeeAuth(true));
              navigate("/adminhome/admindashbord");
            }
          } else {
            // setLoading(false);
            toast.error(data?.data?.mssg, {
              position: "top-center",
              autoClose: 1000,
            });
          }
        }
      } catch (error) {
        console.error("Login failed:", error);
        toast.error(error, {
          position: "top-center",
          autoClose: 1000,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const logout = async () => {
    if (getEmployeeAllDetails.type === "Admin") {
      dispatch(setEmployeeAuth(false));
      sessionStorage.removeItem("authToken");
      await persistor.flush();
      await persistor.purge();
      window.location.reload();
      navigate("/login");
    } else {
      setLoading(true);

      try {
        const data = await axios(`${BaseUrl}${Api.LOGOUT}`, {
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            email: getEmployeeAllDetails?.email,
            login_token: getEmployeeAllDetails?.token,
            type: getEmployeeAllDetails?.type,
          },
        });

        if (data?.data?.valid === false) {
          setLoading(false);
          toast.error(data?.data?.mssg[0], {
            position: "top-center",
            autoClose: 1000,
          });
          setLoading(false);
          toast.success(data?.data?.mssg, {
            position: "top-center",
            autoClose: 1000,
          });
          dispatch(setEmployeeAuth(false));
          sessionStorage.removeItem("authToken");
          await persistor.flush();
          await persistor.purge();
          window.location.reload();
        } else {
          if (data?.data?.mssg === "Logout successfully") {
            setLoading(false);
            toast.success(data?.data?.mssg, {
              position: "top-center",
              autoClose: 1000,
            });
            dispatch(setEmployeeAuth(false));
            sessionStorage.removeItem("authToken");
            await persistor.flush();
            await persistor.purge();
            window.location.reload();
          } else {
            toast.error(data?.data?.mssg, {
              position: "top-center",
              autoClose: 1000,
            });
            setLoading(false);
            toast.success(data?.data?.mssg, {
              position: "top-center",
              autoClose: 1000,
            });
            dispatch(setEmployeeAuth(false));
            sessionStorage.removeItem("authToken");
            await persistor.flush();
            await persistor.purge();
            window.location.reload();
          }
        }
      } catch (error) {
        console.error("Login failed:", error);
        toast.error(error, {
          position: "top-center",
          autoClose: 1000,
        });
        dispatch(setEmployeeAuth(false));
        sessionStorage.removeItem("authToken");
        await persistor.flush();
        await persistor.purge();
        window.location.reload();
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{ getEmployeeAuth, login, logout, loading, setLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
