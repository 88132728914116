import React, { useState } from "react";
import "./LoginForm.css";
import { FaUser, FaLock } from "react-icons/fa";
import { toast } from "react-toastify";
import { useAuth } from "../Component/Authentication/AuthContext";
import { Form, Input, Button, Checkbox, Select } from "antd";
import Image from "../Assets/test.jpg";

const { Option } = Select;

export const LoginForm = () => {
  const { login } = useAuth();
  const [username, setUsername] = useState("");

  const onFinish = async (values) => {
    await login(
      values.username + "@cvinfotech.com",
      values.password,
      values.role
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;

    if (!value.includes("@")) {
      setUsername(value);
    }
  };
  return (
    <div className="login-page">
      <div className="login-box">
        <div className="illustration-wrapper">
          <img src={Image} alt="Login" />
        </div>
        <Form
          name="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <p className="form-title">Welcome back</p>
          <p className="form-title1">Login to the Dashboard</p>

          {/* <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input
              placeholder="Username"
              prefix={<FaUser />} //
              
            />
          </Form.Item> */}

          <Form.Item
            name="username"
            // rules={[{ required: true, message: "Please input your username!" }]}
            rules={[
              { required: true, message: "Please input your username!" },
              {
                validator: (_, value) => {
                  if (value && value.includes("@")) {
                    return Promise.reject(
                      new Error(
                        "Please do not include a domain (e.g. @cvinfotech.com)."
                      )
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              value={username}
              onChange={handleUsernameChange}
              placeholder="Username"
              suffix="@cvinfotech.com" // This will show the domain inside the input field
              prefix={<FaUser />} // Add icon to the input field
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              placeholder="Password"
              prefix={<FaLock />} // Add icon to input field
            />
          </Form.Item>
          <Form.Item
            name="role"
            rules={[{ required: true, message: "Please select a role!" }]}
          >
            <Select placeholder="Select a role">
              <Option value="Admin">Admin</Option>
              <Option value="Employee">Employee</Option>
            </Select>
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              LOGIN
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
