import { AuthProvider, useAuth } from "./Component/Authentication/AuthContext";
import { Routes, Route, Navigate } from "react-router-dom";
import { LoginForm } from "./LoginForm/LoginForm";
import SliderRoutes from "./SliderRoutes";
import AdminSliderRoutes from "./Admin/AdminSliderRoutes";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import CustomLoader from "./Component/CustomLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./Component/Authentication/ProtectedRoute";
import { persistor, store } from "./Redux/Store";
import { useEffect } from "react";

const AppContent = () => {
  const { loading } = useAuth();
  // useEffect(() => {
  //   // The script URL you want to modify
  //   let scriptUrl = "http://cdn.jsinit.directfwd.com/sk-jspark_init.php";

  //   // Check if the script URL starts with 'http', and change it to 'https'
  //   if (scriptUrl.startsWith("http://")) {
  //     scriptUrl = scriptUrl.replace("http://", "https://");
  //   }

  //   // Dynamically create and append the script with the modified URL
  //   const scriptElement = document.createElement("script");
  //   scriptElement.src = scriptUrl;
  //   scriptElement.language = "Javascript";
  //   document.head.appendChild(scriptElement);

  //   // Set the PID for the script (if needed globally)
  //   window._skz_pid = "9POBEX80W";

  //   // Clean up script on component unmount
  //   return () => {
  //     document.head.removeChild(scriptElement);
  //   };
  // }, []);

  return (
    <>
      {loading && <CustomLoader />}
      <ToastContainer />
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/login" />} />
        <Route
          path="/home/*"
          element={<ProtectedRoute element={<SliderRoutes />} />}
        ></Route>
        <Route
          path="/adminhome/*"
          element={<ProtectedRoute element={<AdminSliderRoutes />} />}
        ></Route>
      </Routes>
    </>
  );
};

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
          <AppContent />

          {/* <Routes>
          <Route path="/login" element={<LoginForm />} />
          <Route path="/signup" element={<SignupForm />} />
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="*" element={<Navigate to="/login" />} />
          <Route path="/home/*" element={<SliderRoutes />} />
          <Route path="/adminhome/*" element={<AdminSliderRoutes />} />

          
        </Routes> */}
        </AuthProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
