import axios from "axios";
import React, { useEffect, useState } from "react";
import { Api, BaseUrl, ImagePath } from "../../Config/Api";
import { useAuth } from "../../Component/Authentication/AuthContext";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setEmployeeHoliday } from "../../Redux/Action";

const AdminHoliday = () => {
  const [image, setImage] = useState(null);

  const [isFullScreen, setIsFullScreen] = useState(false); // State to toggle full-screen mode
  const { setLoading, logout } = useAuth();
  const token = sessionStorage.getItem("authToken");
  const getEmployeeHoliday = useSelector((state) => state.getEmployeeHoliday);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const dispatch = useDispatch();
  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      setImage(file);

      const previewUrl = URL.createObjectURL(file);
      setImagePreviewUrl(previewUrl);
    }
  };
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  const uploadImage = async () => {
    try {
      const formDataToSend = new FormData();

      if (image) {
        setLoading(true);
        formDataToSend.append("login_token", token);
        formDataToSend.append("image", image);
        const response = await axios.post(
          `${BaseUrl}${Api.ADD_HOLIDAY}`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response?.data?.valid === false) {
          setLoading(false);
          toast.error(response?.data?.mssg[0], {
            position: "top-center",
            autoClose: 1000,
          });
        } else {
          if (
            response?.data?.mssg === "unothorized token please go to login page"
          ) {
            logout();
            setLoading(false);
          } else {
            if (response?.data?.success === true) {
              toast.success("Image upload  successfully", {
                position: "top-center",
                autoClose: 1000,
              });
              getHolidayList();
              setLoading(false);
            } else if (response?.data?.success === false) {
              toast.error(response?.data?.mssg, {
                position: "top-center",
                autoClose: 1000,
              });
              setLoading(false);
            }
          }
        }
      } else {
        toast.error("Please select image", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("API call failed:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getHolidayList();
  }, []);
  const getHolidayList = async () => {
    try {
      const responseData = await axios.get(
        `${BaseUrl}${Api.GET_HOLIDAY}?login_token=${token}`
      );

      if (responseData?.data?.valid === false) {
        setLoading(false);
        toast.error(responseData?.data?.mssg[0], {
          position: "top-center",
          autoClose: 1000,
        });
      } else {
        if (
          responseData?.data?.mssg ===
          "unothorized token please go to login page"
        ) {
          setLoading(false);
          logout();
        } else {
          dispatch(setEmployeeHoliday(responseData?.data?.data));

          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("API call failed:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <div className="searchBar-container">
        <div className="searchBar-container1">
          <input
            type="file"
            id="search-query"
            name="query"
            accept="image/*"
            onChange={handleImageUpload}
            className="searchBar-input"
            autoComplete="current-query"
          />
          
          <button
            className="searchBar-button"
            onClick={() => {
              uploadImage();
            }}
          >
            Upload Image
          </button>
        </div>
      </div>

      {imagePreviewUrl ? (
        <>
          <div
            style={{
              marginTop: 20,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <h1>Uploaded Image:</h1>
          </div>
          <div
            style={{
              marginTop: 20,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
              position: isFullScreen ? "fixed" : "relative",
              top: isFullScreen ? 0 : "auto",
              left: isFullScreen ? 0 : "auto",
              width: isFullScreen ? "100vw" : "auto",
              height: isFullScreen ? "100vh" : "auto",

              zIndex: isFullScreen ? 1000 : "auto",
              backgroundColor: isFullScreen
                ? "rgba(0, 0, 0, 0.9)"
                : "transparent",
            }}
            onClick={toggleFullScreen}
          >
            <img
              src={imagePreviewUrl}
              style={{
                maxWidth: "100%",

                height: isFullScreen ? "100vh" : "600px",
                objectFit: "fill",
              }}
              alt="Preview"
            />
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              marginTop: 20,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <h1>Uploaded Image:</h1>
          </div>
          <div
            style={{
              marginTop: 20,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
              // position: isFullScreen ? "fixed" : "relative",
              top: isFullScreen ? 0 : "auto",
              left: isFullScreen ? 0 : "auto",
              width: isFullScreen ? "100vw" : "auto",
              height: isFullScreen ? "100vh" : "auto",

              zIndex: isFullScreen ? 1000 : "auto",
              backgroundColor: isFullScreen
                ? "rgba(0, 0, 0, 0.9)"
                : "transparent",
            }}
            onClick={toggleFullScreen}
          >
            <img
              src={ImagePath + getEmployeeHoliday[0]?.image}
              style={{
                maxWidth: "100%",

                height: isFullScreen ? "100vh" : "600px",
                objectFit: "fill",
              }}
              alt="Preview"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AdminHoliday;
