import React, { useState } from "react";
import "./Attendence.css";
import Attendance from "../../Assets/attendance.png";
import { useDispatch, useSelector } from "react-redux";

import { BiBuildings } from "react-icons/bi";
import { TfiLocationPin } from "react-icons/tfi";
import { LuPhone } from "react-icons/lu";

import { ImagePath } from "../../Config/Api";
import { MdLaptopChromebook, MdOutlineMailOutline } from "react-icons/md";
const Attendence = () => {
  const dispatch = useDispatch();
  const getEmployeeDetails = useSelector((state) => state.getEmployeeDetails);
  const getEmployeeindex = useSelector((state) => state.getEmployeeindex);
  const [allAttendance, setAllAttendance] = useState([])
  const [employeeAttendance, setEmployeeAttendance] = useState([])
  const [filteredCategories, setFilteredCategories] =
    useState(getEmployeeDetails);
 
  const [isOn, setIsOn] = useState(false);
  const token = sessionStorage.getItem("authToken");
  return (
    <div className="mainDivlea1">
    <h1
      style={{ fontWeight: "700", fontSize: 30, color: "black", padding: 15 }}
    >
      Attendance Sheet
    </h1>
    <div className="mainDiv3">
      <div className="detailContainer1">
        <div
          style={{
            width: "100%",
            // height: 100,
            padding: 5,
            flexDirection: "row",
            display: "flex",
            background: "#f5f5f5",
            borderWidth: 1,
          }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                paddingLeft: 15,
                paddingTop: 10,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h1
                style={{
                  color: "#155596",
                  fontSize: 25,
                  fontWeight: "700",
                }}
              >
                {getEmployeeDetails[getEmployeeindex]?.name}
              </h1>
              <h3
                style={{
                  marginLeft: 10,
                  color: "#155596",
                  fontSize: 15,
                  fontWeight: "400",
                }}
              >
                {getEmployeeDetails[getEmployeeindex]?.employee_code}
              </h3>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                paddingLeft: 15,
                paddingTop: 10,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <MdLaptopChromebook color="#155596" size={20} />
              <h1
                style={{
                  fontWeight: "500",
                  fontSize: 15,
                  textAlign: "center",
                  marginLeft: 10,
                  marginTop: 5,
                  color: "#155596",
                }}
              >
                {getEmployeeDetails[getEmployeeindex]?.designation}
              </h1>
              <BiBuildings
                color="#155596"
                size={20}
                style={{ marginLeft: 15 }}
              />
              <h1
                style={{
                  fontWeight: "500",
                  fontSize: 15,
                  textAlign: "center",
                  marginLeft: 10,
                  marginTop: 5,
                  color: "#155596",
                }}
              >
                {getEmployeeDetails[getEmployeeindex]?.department}
              </h1>
              <TfiLocationPin
                color="#155596"
                size={20}
                style={{ marginLeft: 15 }}
              />
              <h1
                style={{
                  fontWeight: "500",
                  fontSize: 15,
                  textAlign: "center",
                  marginLeft: 10,
                  marginTop: 5,
                  color: "#155596",
                }}
              >
                {getEmployeeDetails[getEmployeeindex]?.location}
              </h1>
              <LuPhone color="#155596" size={20} style={{ marginLeft: 15 }} />
              <h1
                style={{
                  fontWeight: "500",
                  fontSize: 15,
                  textAlign: "center",
                  marginLeft: 10,
                  marginTop: 5,
                  color: "#155596",
                }}
              >
                {getEmployeeDetails[getEmployeeindex]?.mobile}
              </h1>
              <MdOutlineMailOutline
                color="#155596"
                size={20}
                style={{ marginLeft: 15 }}
              />
              <h1
                style={{
                  fontWeight: "500",
                  fontSize: 15,
                  textAlign: "center",
                  marginLeft: 10,
                  marginTop: 5,
                  color: "#155596",
                }}
              >
                {getEmployeeDetails[getEmployeeindex]?.email}
              </h1>
            </div>
          </div>

          <div
            style={{
              width: "90px",
              height: "90px",

              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
              display: "flex",
              borderRadius: "50%",
              overflow: "hidden",
            }}
          >
            {getEmployeeDetails[getEmployeeindex]?.image == null ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  backgroundColor: "gray",
                }}
              >
                <h1
                  style={{
                    fontSize: 30,
                    fontFamily: "cursive",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {getEmployeeDetails[getEmployeeindex]?.name
                    .charAt(0)
                    .toUpperCase()}
                </h1>
              </div>
            ) : (
              <img
                src={ImagePath + getEmployeeDetails[getEmployeeindex]?.image}
                alt="circle"
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                  display: "flex",
                  objectFit: "cover", // Ensures the image covers the entire area without stretching
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: 100,
            marginTop: 20,
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "85%",
              height: 100,
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "23%",
                height: "100%",
                borderWidth: 1,
                borderColor: "#fac4a7",
                flexDirection: "row",
                display: "flex",
                background: "#fff6f1",
              }}
            >
              <div
                style={{ width: 15, height: "100%", background: "#f97d38" }}
              ></div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: 10,
                }}
              >
                <div>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "700",
                      color: "#2f669e",
                    }}
                  >
                    Total Leaves
                  </h1>

                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "700",
                      color: "#f97d38",
                    }}
                  >
                    12
                  </h1>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "23%",
                height: "100%",
                borderWidth: 1,
                borderColor: "#0f8e4d",
                flexDirection: "row",
                display: "flex",
                background: "#cdf2df",
              }}
            >
              <div
                style={{ width: 15, height: "100%", background: "#32b472" }}
              ></div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: 10,
                }}
              >
                <div>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "700",
                      color: "#2f669e",
                    }}
                  >
                    Leave Collected
                  </h1>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "700",
                      color: "#32b472",
                    }}
                  >
                    12
                  </h1>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "23%",
                height: "100%",
                borderWidth: 1,
                borderColor: "#e2ac22",
                flexDirection: "row",
                display: "flex",
                background: "#fffaee",
              }}
            >
              <div
                style={{ width: 15, height: "100%", background: "#ffc834" }}
              ></div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: 10,
                }}
              >
                <div>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "700",
                      color: "#2f669e",
                    }}
                  >
                    Paid Leaves
                  </h1>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "700",
                      color: "#ffc834",
                    }}
                  >
                    12
                  </h1>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "23%",
                height: "100%",
                borderWidth: 1,
                borderColor: "#d363ed",
                flexDirection: "row",
                display: "flex",
                background: "#ead2ef",
              }}
            >
              <div
                style={{ width: 15, height: "100%", background: "#c280d0" }}
              ></div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: 10,
                }}
              >
                <div>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "700",
                      color: "#2f669e",
                    }}
                  >
                    Unpaid Leaves
                  </h1>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "700",
                      color: "#c280d0",
                    }}
                  >
                    12
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: 50,

            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        ></div>
        <div style={{ width: "100%", marginTop: 20 }}>
          <div style={{ marginBottom: 20 }}>
            <h1 style={{ color: "#155596", fontWeight: "700", fontSize: 25 }}>
              Attendance History
            </h1>
          </div>
          <table className="employee-table1">
            <thead>
              <tr>
                <th>S.N</th>
                <th>Reporting Manager</th>
                <th>Emp.Status</th>
                <th>Day</th>
                <th>Date</th>
                <th>Month</th>
                <th>Year</th>
                <th>In-Time</th>
                <th>Out-Time</th>
              </tr>
            </thead>

            <tbody>
              {employeeAttendance?.map((emp, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{emp?.reporting_manager}</td>
                  <td>{emp?.status}</td>
                  <td>{emp?.day}</td>
                  <td>{emp?.date}</td>
                  <td>{emp?.month}</td>
                  <td>{emp?.year}</td>
                  <td>{emp?.login_time}</td>
                  <td>{emp?.logout_time}</td>

      
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
     
    </div>
    </div>
   
  );
};

export default Attendence;
