export const BaseUrl =
  "https://hrmanagement.cvinfotechserver.com/CV/public/api/";
export const ImagePath =
  "https://hrmanagement.cvinfotechserver.com/CV/public/images/";
export const Api = {
  //Admin Api End   Points
  LOGIN: "login", //done
  ADD_EMPLOYEE_DETAILS: "add_employee",
  GET_EMPLOYEE: "get_users", //done
  DELETE_EMPLOYEE: "delete_employee",
  UPDATE_EMPLOYEE_DETAILS: "update_employee",
  ADD_LEAVE: "add_leave",
  GET_EMPLOYEE_LEAVE_DETAILS: "get_user_leave_data",
  UPDATE_EMPLOYEE_LEAVE: "update_add_leave",
  GET_ATTENDANCE: "get_attndance",
  GET_USER_ATTENDANCE: "get_user_attndance",
  ADD_HOLIDAY: "add_holidays",
  //Employee Api End Point
  LOGOUT: "logout",
  GET_EMPLOYEE_PROFILE: "get_employee_profile",
  GET_HOLIDAY: "get_holidays",
};
