import { Route, Routes } from "react-router-dom";
import React from 'react'

import Dashbord from "./Employee/Page/Dashbord";
import Attendence from "./Employee/Page/Attendence";
import Leaves from "./Employee/Page/Leaves";
import Profile from "./Employee/Page/Profile";
import RootLayout from "./Employee/RootLayout";
import Holiday from "./Employee/Page/Holiday";


const SliderRoutes = () => {
  return (

    <RootLayout>
      <Routes>
        <Route path="/dashbord" element={<Dashbord />} />
        <Route path="/*" element={<Dashbord />} />
        <Route path="/attendance" element={<Attendence />} />
        <Route path="/leaves" element={<Leaves />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/holiday" element={<Holiday />} />
       
      </Routes>
    </RootLayout>
  )
}

export default SliderRoutes