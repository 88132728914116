import React, { useEffect, useState } from "react";
import "./Attendance.css";
import { useDispatch, useSelector } from "react-redux";
import { MdLaptopChromebook, MdOutlineMailOutline } from "react-icons/md";
import { BiBuildings } from "react-icons/bi";
import { TfiLocationPin } from "react-icons/tfi";
import { LuPhone } from "react-icons/lu";
import { BsFillLightbulbFill } from "react-icons/bs";
import { FaSearch, FaUser } from "react-icons/fa";
import { Api, BaseUrl, ImagePath } from "../../Config/Api";
import { useAuth } from "../../Component/Authentication/AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import {setEmployeeindex } from "../../Redux/Action";



const Attendance = () => {
  const dispatch = useDispatch();
  const getEmployeeDetails = useSelector((state) => state.getEmployeeDetails);
  const getEmployeeindex = useSelector((state) => state.getEmployeeindex);
  const [allAttendance, setAllAttendance] = useState([])
  const [employeeAttendance, setEmployeeAttendance] = useState([])
  const [filteredCategories, setFilteredCategories] =
    useState(getEmployeeDetails);
 
  const [isOn, setIsOn] = useState(false);
  const token = sessionStorage.getItem("authToken");
  console.log("SDfsdfdsgsdg",token)
  const { setLoading, logout } = useAuth();


  useEffect(() => {
    getAllUserAttendanceData()
    if (getEmployeeDetails.length > 0) {
    
      getEmployeeAttendance(0);
    }

  }, [])
  const getEmployeeAttendance = async (data) => {
    setLoading(true);

    try {
      const responseData = await axios.get(
        `${BaseUrl}${Api.GET_USER_ATTENDANCE}?login_token=${token}&employee_id=${getEmployeeDetails[data]?.id}`
      );

      if (
        responseData?.data?.mssg === "unothorized token please go to login page"
      ) {
        setLoading(false);
        logout();
      } else {

        setEmployeeAttendance(responseData?.data?.data)
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("API call failed:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }


  }
  const getAllUserAttendanceData = async () => {

    setLoading(true);

    try {
      const responseData = await axios.get(
        `${BaseUrl}${Api.GET_ATTENDANCE}?login_token=${token}`
      );

      if (
        responseData?.data?.mssg === "unothorized token please go to login page"
      ) {
        setLoading(false);
        logout();
      } else {

        setAllAttendance(responseData?.data?.data)
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("API call failed:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }

  }
  const toggleSwitch = () => {
    setIsOn(!isOn);
  };
  const [query, setQuery] = useState("");
  const handleInputChange = (event) => {
    setQuery(event.target.value);
    updateFilteredCategories(event.target.value);
  };
  const updateFilteredCategories = (searchTerm) => {
    const lowerCaseQuery = searchTerm.trim().toLowerCase();

    const filteredItems = getEmployeeDetails.filter((item) => {
      return (
        item?.name.toLowerCase().includes(lowerCaseQuery) ||
        item?.employee_code.toLowerCase().includes(lowerCaseQuery) ||
        item?.email.toLowerCase().includes(lowerCaseQuery)
      );
    });

    setFilteredCategories(filteredItems);
  };
  const handleRowClick = (employeeId) => {
    const index = getEmployeeDetails.findIndex((emp) => emp?.id === employeeId);
    // dispatch(setEmployeeindex(index));
    // getEmployeeLeavdDetails(index);
   
    dispatch(setEmployeeindex(index))
  
    getEmployeeAttendance(index)

    
  };
  return (
    <div className="mainDivleave">
      <h1
        style={{ fontWeight: "700", fontSize: 30, color: "black", padding: 15 }}
      >
        Attendance Sheet
      </h1>
      <div>
        <div className="switch-container">
          <label className="switch">
            <input type="checkbox" checked={isOn} onChange={toggleSwitch} />
            <span className="slider"></span>
          </label>
          <p>{isOn ? "Switch to Full Mode" : "Switch to Singal Mode"}</p>
        </div>
      </div>

      {isOn === true ? (
        <div className="mainDiv2">
          <div className="detailContainer">
            <div
              style={{
                width: "100%",
                // height: 100,
                padding: 5,
                flexDirection: "row",
                display: "flex",
                background: "#f5f5f5",
                borderWidth: 1,
              }}
            >
              <div style={{ width: "93%", height: "97%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    paddingLeft: 15,
                    paddingTop: 10,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <h1
                    style={{
                      color: "#155596",
                      fontSize: 25,
                      fontWeight: "700",
                    }}
                  >
                    {getEmployeeDetails[getEmployeeindex]?.name}
                  </h1>
                  <h3
                    style={{
                      marginLeft: 10,
                      color: "#155596",
                      fontSize: 15,
                      fontWeight: "400",
                    }}
                  >
                    {getEmployeeDetails[getEmployeeindex]?.employee_code}
                  </h3>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    paddingLeft: 15,
                    paddingTop: 10,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <MdLaptopChromebook color="#155596" size={20} />
                  <h1
                    style={{
                      fontWeight: "500",
                      fontSize: 15,
                      textAlign: "center",
                      marginLeft: 10,
                      marginTop: 5,
                      color: "#155596",
                    }}
                  >
                    {getEmployeeDetails[getEmployeeindex]?.designation}
                  </h1>
                  <BiBuildings
                    color="#155596"
                    size={20}
                    style={{ marginLeft: 15 }}
                  />
                  <h1
                    style={{
                      fontWeight: "500",
                      fontSize: 15,
                      textAlign: "center",
                      marginLeft: 10,
                      marginTop: 5,
                      color: "#155596",
                    }}
                  >
                    {getEmployeeDetails[getEmployeeindex]?.department}
                  </h1>
                  <TfiLocationPin
                    color="#155596"
                    size={20}
                    style={{ marginLeft: 15 }}
                  />
                  <h1
                    style={{
                      fontWeight: "500",
                      fontSize: 15,
                      textAlign: "center",
                      marginLeft: 10,
                      marginTop: 5,
                      color: "#155596",
                    }}
                  >
                    {getEmployeeDetails[getEmployeeindex]?.location}
                  </h1>
                  <LuPhone
                    color="#155596"
                    size={20}
                    style={{ marginLeft: 15 }}
                  />
                  <h1
                    style={{
                      fontWeight: "500",
                      fontSize: 15,
                      textAlign: "center",
                      marginLeft: 10,
                      marginTop: 5,
                      color: "#155596",
                    }}
                  >
                    {getEmployeeDetails[getEmployeeindex]?.mobile}
                  </h1>
                  <MdOutlineMailOutline
                    color="#155596"
                    size={20}
                    style={{ marginLeft: 15 }}
                  />
                  <h1
                    style={{
                      fontWeight: "500",
                      fontSize: 15,
                      textAlign: "center",
                      marginLeft: 10,
                      marginTop: 5,
                      color: "#155596",
                    }}
                  >
                    {getEmployeeDetails[getEmployeeindex]?.email}
                  </h1>
                </div>
              </div>

              <div
                style={{
                  width: "90px",
                  height: "90px",

                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 10,
                  display: "flex",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              >
                {getEmployeeDetails[getEmployeeindex]?.image == null ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      backgroundColor: "gray",
                    }}
                  >
                    <h1
                      style={{
                        fontSize: 30,
                        fontFamily: "cursive",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {getEmployeeDetails[getEmployeeindex]?.name
                        .charAt(0)
                        .toUpperCase()}
                    </h1>
                  </div>
                ) : (
                  <img
                    src={ImagePath + getEmployeeDetails[getEmployeeindex]?.image}
                    alt="circle"
                    style={{
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                      display: "flex",
                      objectFit: "cover", // Ensures the image covers the entire area without stretching
                    }}
                  />
                )}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: 100,
                marginTop: 20,
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "85%",
                  height: 100,
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "23%",
                    height: "100%",
                    borderWidth: 1,
                    borderColor: "#fac4a7",
                    flexDirection: "row",
                    display: "flex",
                    background: "#fff6f1",
                  }}
                >
                  <div
                    style={{ width: 15, height: "100%", background: "#f97d38" }}
                  ></div>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      display: "flex",
                      marginTop: 10,
                    }}
                  >
                    <div>
                      <h1
                        style={{
                          textAlign: "center",
                          fontSize: 25,
                          fontWeight: "700",
                          color: "#2f669e",
                        }}
                      >
                        Total Leaves
                      </h1>

                      <h1
                        style={{
                          textAlign: "center",
                          fontSize: 25,
                          fontWeight: "700",
                          color: "#f97d38",
                        }}
                      >
                        12
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "23%",
                    height: "100%",
                    borderWidth: 1,
                    borderColor: "#0f8e4d",
                    flexDirection: "row",
                    display: "flex",
                    background: "#cdf2df",
                  }}
                >
                  <div
                    style={{ width: 15, height: "100%", background: "#32b472" }}
                  ></div>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      display: "flex",
                      marginTop: 10,
                    }}
                  >
                    <div>
                      <h1
                        style={{
                          textAlign: "center",
                          fontSize: 25,
                          fontWeight: "700",
                          color: "#2f669e",
                        }}
                      >
                        Leave Collected
                      </h1>
                      <h1
                        style={{
                          textAlign: "center",
                          fontSize: 25,
                          fontWeight: "700",
                          color: "#32b472",
                        }}
                      >
                        12
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "23%",
                    height: "100%",
                    borderWidth: 1,
                    borderColor: "#e2ac22",
                    flexDirection: "row",
                    display: "flex",
                    background: "#fffaee",
                  }}
                >
                  <div
                    style={{ width: 15, height: "100%", background: "#ffc834" }}
                  ></div>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      display: "flex",
                      marginTop: 10,
                    }}
                  >
                    <div>
                      <h1
                        style={{
                          textAlign: "center",
                          fontSize: 25,
                          fontWeight: "700",
                          color: "#2f669e",
                        }}
                      >
                        Paid Leaves
                      </h1>
                      <h1
                        style={{
                          textAlign: "center",
                          fontSize: 25,
                          fontWeight: "700",
                          color: "#ffc834",
                        }}
                      >
                        12
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "23%",
                    height: "100%",
                    borderWidth: 1,
                    borderColor: "#d363ed",
                    flexDirection: "row",
                    display: "flex",
                    background: "#ead2ef",
                  }}
                >
                  <div
                    style={{ width: 15, height: "100%", background: "#c280d0" }}
                  ></div>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      display: "flex",
                      marginTop: 10,
                    }}
                  >
                    <div>
                      <h1
                        style={{
                          textAlign: "center",
                          fontSize: 25,
                          fontWeight: "700",
                          color: "#2f669e",
                        }}
                      >
                        Unpaid Leaves
                      </h1>
                      <h1
                        style={{
                          textAlign: "center",
                          fontSize: 25,
                          fontWeight: "700",
                          color: "#c280d0",
                        }}
                      >
                        12
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: 50,

                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
   
            </div>
            <div style={{ width: "100%", marginTop: 20 }}>
              <div style={{ marginBottom: 20 }}>
                <h1
                  style={{ color: "#155596", fontWeight: "700", fontSize: 25 }}
                >
                Attendance  History
                </h1>
              </div>
              <table className="employee-table1">
                <thead>
                  <tr>
                    <th>S.N</th>
                    <th>Reporting Manager</th>
                    <th>Emp.Status</th>
                    <th>Day</th>
                    <th>Date</th>
                    <th>Month</th>
                    <th>Year</th>
                    <th>In-Time</th>
                    <th>Out-Time</th>
                  </tr>
                </thead>

                <tbody>
                  {employeeAttendance?.map((emp, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{emp?.reporting_manager}</td>
                    <td>{emp?.status}</td>
                    <td>{emp?.day}</td>
                    <td>{emp?.date}</td>
                    <td>{emp?.month}</td>
                    <td>{emp?.year}</td>
                    <td>{emp?.login_time}</td>
                    <td>{emp?.logout_time}</td>
              
{/* 
                    <td>
                      <button className="action-button" onClick={() => {}}>
                        <BsThreeDotsVertical />
                      </button>
                    </td> */}
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="detailContainer2">
            <h1
              style={{
                fontWeight: "700",
                fontSize: 25,
                color: "black",
              }}
            >
              Employee
            </h1>
            <div className="searchBar-2">
              <div className="searchBar-wrapper">
                <input
                  type="text"
                  id="search-query"
                  name="query"
                  value={query}
                  onChange={handleInputChange}
                  placeholder="Search..."
                  className="searchBar-input"
                  autoComplete="current-query"
                />

                <FaSearch className="search-icon" />
              </div>
            </div>
            {/* {filteredCategories.map((emp, index) => (
              <div key={emp.id}>
                <div
                  className={`employee-item ${getEmployeeindex === index ? "selected" : ""
                    }`}
                  onClick={() => handleRowClick(index)}
                >
                  <FaUser className="user-icon" />
                  <div>
                    <h3 className="employee-name">{emp.name}</h3>
                    <h4 className="employee-name1">{emp.employee_code}</h4>
                  </div>
                </div>
           
              </div>
            ))} */}
            {filteredCategories?.map((emp) => (
            <div key={emp?.id}>
              <div
                className={`employee-item ${
                  getEmployeeDetails.findIndex((e) => e?.id === emp?.id) ===
                  getEmployeeindex
                    ? "selected"
                    : ""
                }`}
                onClick={() => handleRowClick(emp?.id)} // Pass the employee ID instead of index
              >
                <FaUser className="user-icon" />
                <div>
                  <h3 className="employee-name">{emp?.name}</h3>
                  <h4 className="employee-name1">{emp?.employee_code}</h4>
                </div>
              </div>
            </div>
          ))}
          </div>
        </div>
      ) : (
        <div className="card-container1">
          <div className="table-container1">
            <table className="employee-table1">
              <thead>
                <tr>
                  <th>S.N</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Emp. Code</th>
                  <th>Email ID</th>
                  <th>Contact</th>
                  <th>Designation</th>
                  <th>Department</th>
                  <th>Reporting Manager</th>
                  <th>Emp. Status</th>
                  <th>Day Name</th>
                  <th>Month Date</th>
                  <th>IN Time</th>
                  <th>OUT Time</th>
                </tr>
              </thead>
              <tbody>
                {allAttendance?.map((emp, index) => {

                  // const timeToMinutes = (timeString) => {
                  //   const [hours, minutes] = timeString?.split(':').map(Number);
                  //   return hours * 60 + minutes;
                  // };


                  // const isLateLogin = (loginTime) => {
                  //   const loginMinutes = timeToMinutes(loginTime);
                  //   const limitMinutes = 9 * 60 + 15; // 9:15 AM in minutes



                  //   return loginMinutes > limitMinutes;
                  // };
                  // const logoutCellStyle = isLateLogin(emp.login_time)
                  //   ? { backgroundColor: 'red', color: 'white' }
                  //   : { backgroundColor: 'green', color: 'white' };
                  return (
                    <tr key={emp.id}>
                      <td>{index + 1}</td>
                      <td>{emp?.name}</td>
                      <td
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        {emp?.image == null ? (
                          <div
                            style={{
                              width: '70px',
                              height: '70px',
                              borderRadius: '70%',
                              justifyContent: 'center',
                              alignItems: 'center',
                              display: 'flex',
                              backgroundColor: 'gray',
                            }}
                          >
                            <h1
                              style={{
                                fontSize: 30,
                                fontFamily: 'cursive',
                                fontWeight: 'bold',
                                color: 'white',
                              }}
                            >
                              {emp?.name.charAt(0).toUpperCase()}
                            </h1>
                          </div>
                        ) : (
                          <img
                            src={ImagePath + emp?.image}
                            alt={emp?.name}
                            style={{
                              width: '70px',
                              height: '70px',
                              borderRadius: '70%',
                              justifyContent: 'center',
                              alignItems: 'center',
                              display: 'flex',
                            }}
                          />
                        )}
                      </td>
                      <td>{emp?.employee_code}</td>
                      <td>{emp?.email}</td>
                      <td>{emp?.mobile}</td>
                      <td>{emp?.designation}</td>
                      <td>{emp?.department}</td>
                      <td>{emp?.reporting_manager}</td>
                      <td>{emp?.status}</td>
                      <td>{emp?.day}</td>
                      <td>{emp?.date}</td>
                      <td  >{emp?.login_time}</td>
                      <td >{emp?.logout_time}</td>
                    </tr>
                  );
                })}
              </tbody>

              {/* <tbody>
                {allAttendance?.map((emp, index) => (
                  
                  <tr key={emp.id}>
                    <td>{index + 1}</td>
                    <td>{emp.name}</td>
                    <td
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {emp.image == null ? (
                        <div
                          style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "70%",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            backgroundColor: "gray",
                          }}
                        >
                          <h1
                            style={{
                              fontSize: 30,
                              fontFamily: "cursive",
                              fontWeight: "bold",
                              color: "white",
                            }}
                          >
                            {emp.name.charAt(0).toUpperCase()}
                          </h1>
                        </div>
                      ) : (
                        <img
                          src={ImagePath + emp.image}
                          alt={emp.name}
                          style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "70%",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        />
                      )}
                    </td>
                    <td>{emp.employee_code}</td>
                    <td>{emp.email}</td>
                    <td>{emp.mobile}</td>
                    <td>{emp.designation}</td>
                    <td>{emp.department}</td>
                    <td>{emp.reporting_manager}</td>
                    <td>{emp.status}</td>
                    <td>{emp.day}</td>
                    <td>{emp.date}</td>
                    <td>{emp.login_time}</td>
                    <td>{emp.logout_time}</td>
                  </tr>
                ))}
              </tbody> */}
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Attendance;