import Type from "../Redux/Constants";
const intialState = {
  getEmployeeDetails: [],
  getEmployeeAuth: false,
  getEmployeeLeaveDetails: [],
  getEmployeeAllDetails: [],
  getEmployeeindex: 0,
  getEmployeeHoliday: [],
};
const EmployeeReduser = (state = intialState, action) => {
  switch (action.type) {
    case Type.EMPLOYEE_DETAILS:
      return { ...state, getEmployeeDetails: action.payload };
    case Type.EMPLOYEE_AUTH:
      return { ...state, getEmployeeAuth: action.payload };
    case Type.EMPLOYEE_LEAVE_DETAIL:
      return { ...state, getEmployeeLeaveDetails: action.payload };
    case Type.EMPLOYEE_ALL_DETAILS:
      return { ...state, getEmployeeAllDetails: action.payload };
    case Type.EMPLOYEE_SELECTED_INDEX:
      return { ...state, getEmployeeindex: action.payload };
    case Type.EMPLOYEE_HOLIDAY:
      return { ...state, getEmployeeHoliday: action.payload };

    default:
      return state;
  }
};
export default EmployeeReduser;
