import React, { useEffect, useState } from "react";
import "./AdminLeaves.css";
import { FaSearch, FaUser } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { MdLaptopChromebook } from "react-icons/md";
import { BiBuildings } from "react-icons/bi";
import { TfiLocationPin } from "react-icons/tfi";
import { LuPhone } from "react-icons/lu";
import { MdOutlineMailOutline } from "react-icons/md";
import { BsFillLightbulbFill } from "react-icons/bs";
import AddLeaveStatusModal from "../AdminComponent/AddLeaveStatusModal";
import { useAuth } from "../../Component/Authentication/AuthContext";
import axios from "axios";
import { Api, BaseUrl, ImagePath } from "../../Config/Api";
import { toast } from "react-toastify";
import { setEmployeeLeaveDetails, setEmployeeindex } from "../../Redux/Action";

import EditLeaveStatusModal from "../AdminComponent/EditLeaveStatusModal";
import { useCallback } from "react";

const AdminLeaves = () => {
  const [query, setQuery] = useState("");
  const { setLoading, logout } = useAuth();
  const [editmodalOpen, setEditModalOpen] = useState(false);
  const [employeeLeave, setEmployeeLeave] = useState();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const getEmployeeDetails = useSelector((state) => state.getEmployeeDetails);
  const getEmployeeindex = useSelector((state) => state.getEmployeeindex);
  const getEmployeeLeaveDetails = useSelector(
    (state) => state.getEmployeeLeaveDetails
  );
  const [filteredCategories, setFilteredCategories] =
    useState(getEmployeeDetails);
  const token = sessionStorage.getItem("authToken");
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    updateFilteredCategories(event.target.value);
  };

  const updateFilteredCategories = (searchTerm) => {
    const lowerCaseQuery = searchTerm.trim().toLowerCase();

    const filteredItems = getEmployeeDetails.filter((item) => {
      return (
        item.name.toLowerCase().includes(lowerCaseQuery) ||
        item.employee_code.toLowerCase().includes(lowerCaseQuery) ||
        item.email.toLowerCase().includes(lowerCaseQuery)
      );
    });

    setFilteredCategories(filteredItems);
  };
  const handleClick = () => {
    // setOpen(true)
    setModalOpen(true);
  };

  const getEmployeeLeavdDetails = useCallback(
    async (data) => {
      setLoading(true);

      try {
        const responseData = await axios.get(
          `${BaseUrl}${Api.GET_EMPLOYEE_LEAVE_DETAILS}?login_token=${token}&id=${getEmployeeDetails[data]?.id}`
        );

        if (
          responseData?.data?.mssg ===
          "unothorized token please go to login page"
        ) {
          setLoading(false);
          logout();
        } else {
          dispatch(setEmployeeLeaveDetails(responseData?.data));

          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("API call failed:", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    [token, dispatch, setLoading, logout]
  );
  // const handleRowClick = (index) => {

  //   dispatch(setEmployeeindex(index));

  //   getEmployeeLeavdDetails(index);
  // };
  const handleRowClick = (employeeId) => {
    setSelectedEmployeeId(employeeId);
    const index = getEmployeeDetails.findIndex((emp) => emp.id === employeeId);
    dispatch(setEmployeeindex(index));
    getEmployeeLeavdDetails(index);
  };
  useEffect(() => {
    if (getEmployeeDetails.length > 0) {
      getEmployeeLeavdDetails(getEmployeeindex);
    }
  }, [dispatch, token, modalOpen, editmodalOpen]);

  const editEmployeeLeaveDetails = (empData) => {
    setEmployeeLeave(empData);
    setEditModalOpen(true);
  };
  return (
    <div className="mainDivleave">
      <h1
        style={{ fontWeight: "700", fontSize: 30, color: "black", padding: 15 }}
      >
        Leave Page
      </h1>
      <div className="mainDiv2">
        <div className="detailContainer">
          <div
            style={{
              width: "100%",
              // height: 100,
              padding: 5,
              flexDirection: "row",
              display: "flex",
              background: "#f5f5f5",
              borderWidth: 1,
            }}
          >
            <div style={{ width: "93%", height: "97%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  paddingLeft: 15,
                  paddingTop: 10,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    color: "#155596",
                    fontSize: 25,
                    fontWeight: "700",
                  }}
                >
                  {getEmployeeDetails[setEmployeeindex]?.name}
                </h1>
                <h3
                  style={{
                    marginLeft: 10,
                    color: "#155596",
                    fontSize: 15,
                    fontWeight: "400",
                  }}
                >
                  {getEmployeeDetails[getEmployeeindex]?.employee_code}
                </h3>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  paddingLeft: 15,
                  paddingTop: 10,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <MdLaptopChromebook color="#155596" size={20} />
                <h1
                  style={{
                    fontWeight: "500",
                    fontSize: 15,
                    textAlign: "center",
                    marginLeft: 10,
                    marginTop: 5,
                    color: "#155596",
                  }}
                >
                  {getEmployeeDetails[getEmployeeindex]?.designation}
                </h1>
                <BiBuildings
                  color="#155596"
                  size={20}
                  style={{ marginLeft: 15 }}
                />
                <h1
                  style={{
                    fontWeight: "500",
                    fontSize: 15,
                    textAlign: "center",
                    marginLeft: 10,
                    marginTop: 5,
                    color: "#155596",
                  }}
                >
                  {getEmployeeDetails[getEmployeeindex]?.department}
                </h1>
                <TfiLocationPin
                  color="#155596"
                  size={20}
                  style={{ marginLeft: 15 }}
                />
                <h1
                  style={{
                    fontWeight: "500",
                    fontSize: 15,
                    textAlign: "center",
                    marginLeft: 10,
                    marginTop: 5,
                    color: "#155596",
                  }}
                >
                  {getEmployeeDetails[getEmployeeindex]?.location}
                </h1>
                <LuPhone color="#155596" size={20} style={{ marginLeft: 15 }} />
                <h1
                  style={{
                    fontWeight: "500",
                    fontSize: 15,
                    textAlign: "center",
                    marginLeft: 10,
                    marginTop: 5,
                    color: "#155596",
                  }}
                >
                  {getEmployeeDetails[getEmployeeindex]?.mobile}
                </h1>
                <MdOutlineMailOutline
                  color="#155596"
                  size={20}
                  style={{ marginLeft: 15 }}
                />
                <h1
                  style={{
                    fontWeight: "500",
                    fontSize: 15,
                    textAlign: "center",
                    marginLeft: 10,
                    marginTop: 5,
                    color: "#155596",
                  }}
                >
                  {getEmployeeDetails[getEmployeeindex]?.email}
                </h1>
              </div>
            </div>

            <div
              style={{
                width: "90px",
                height: "90px",
                background: "green",
                marginLeft: 10,
                borderRadius: "50%", // Makes the div a circle
                overflow: "hidden", // Ensures image stays inside the circle
              }}
            >
              {getEmployeeDetails[getEmployeeindex]?.image == null ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    backgroundColor: "gray",
                  }}
                >
                  <h1
                    style={{
                      fontSize: 30,
                      fontFamily: "cursive",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {getEmployeeDetails[getEmployeeindex]?.name
                      .charAt(0)
                      .toUpperCase()}
                  </h1>
                </div>
              ) : (
                <img
                  src={ImagePath + getEmployeeDetails[getEmployeeindex]?.image}
                  alt="circle"
                  style={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    display: "flex",
                    objectFit: "cover", 
                  }}
                />
              )}
         
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: 100,
              marginTop: 20,
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "85%",
                height: 100,
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "23%",
                  height: "100%",
                  borderWidth: 1,
                  borderColor: "#fac4a7",
                  flexDirection: "row",
                  display: "flex",
                  background: "#fff6f1",
                }}
              >
                <div
                  style={{ width: 15, height: "100%", background: "#f97d38" }}
                ></div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: 10,
                  }}
                >
                  <div>
                    <h1
                      style={{
                        textAlign: "center",
                        fontSize: 25,
                        fontWeight: "700",
                        color: "#2f669e",
                      }}
                    >
                      Total Leaves
                    </h1>

                    <h1
                      style={{
                        textAlign: "center",
                        fontSize: 25,
                        fontWeight: "700",
                        color: "#f97d38",
                      }}
                    >
                      {
                        getEmployeeLeaveDetails?.leave_data
                          ?.total_leave_entitled
                      }
                    </h1>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "23%",
                  height: "100%",
                  borderWidth: 1,
                  borderColor: "#0f8e4d",
                  flexDirection: "row",
                  display: "flex",
                  background: "#cdf2df",
                }}
              >
                <div
                  style={{ width: 15, height: "100%", background: "#32b472" }}
                ></div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: 10,
                  }}
                >
                  <div>
                    <h1
                      style={{
                        textAlign: "center",
                        fontSize: 25,
                        fontWeight: "700",
                        color: "#2f669e",
                      }}
                    >
                      Leave Collected
                    </h1>
                    <h1
                      style={{
                        textAlign: "center",
                        fontSize: 25,
                        fontWeight: "700",
                        color: "#32b472",
                      }}
                    >
                      {getEmployeeLeaveDetails?.leave_data?.leave_collected}
                    </h1>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "23%",
                  height: "100%",
                  borderWidth: 1,
                  borderColor: "#e2ac22",
                  flexDirection: "row",
                  display: "flex",
                  background: "#fffaee",
                }}
              >
                <div
                  style={{ width: 15, height: "100%", background: "#ffc834" }}
                ></div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: 10,
                  }}
                >
                  <div>
                    <h1
                      style={{
                        textAlign: "center",
                        fontSize: 25,
                        fontWeight: "700",
                        color: "#2f669e",
                      }}
                    >
                      Paid Leaves
                    </h1>
                    <h1
                      style={{
                        textAlign: "center",
                        fontSize: 25,
                        fontWeight: "700",
                        color: "#ffc834",
                      }}
                    >
                      {/* {getEmployeeLeaveDetails[0]?.paid_leave_taken} */}
                      {getEmployeeLeaveDetails?.leave_data?.paid_leave_taken}
                    </h1>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "23%",
                  height: "100%",
                  borderWidth: 1,
                  borderColor: "#d363ed",
                  flexDirection: "row",
                  display: "flex",
                  background: "#ead2ef",
                }}
              >
                <div
                  style={{ width: 15, height: "100%", background: "#c280d0" }}
                ></div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: 10,
                  }}
                >
                  <div>
                    <h1
                      style={{
                        textAlign: "center",
                        fontSize: 25,
                        fontWeight: "700",
                        color: "#2f669e",
                      }}
                    >
                      Unpaid Leaves
                    </h1>
                    <h1
                      style={{
                        textAlign: "center",
                        fontSize: 25,
                        fontWeight: "700",
                        color: "#c280d0",
                      }}
                    >
                      {getEmployeeLeaveDetails?.leave_data?.unpaid_leave_taken}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "15%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <button className="searchBar-button1" onClick={handleClick}>
                Apply Leave
              </button>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: 50,

              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <BsFillLightbulbFill size={20} />
            <h1
              style={{
                fontSize: 16,
                fontWeight: "400",
                textAlign: "center",
                marginLeft: 10,
                marginTop: 10,
              }}
            >
              You will get 1 leave on every month after completed Prohibition
            </h1>
          </div>
          <div style={{ width: "100%", marginTop: 20 }}>
            <div style={{ marginBottom: 20 }}>
              <h1 style={{ color: "#155596", fontWeight: "700", fontSize: 25 }}>
                Leave History
              </h1>
            </div>
            <table className="employee-table1">
              <thead>
                <tr>
                  <th>S.N</th>
                  <th>Leave leave_name</th>
                  <th>Leave Code</th>
                  <th>Leave Type</th>
                  <th>Leave Status</th>
                  <th>Leave Unit</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Total Days</th>
                  <th>Leave Reason</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {getEmployeeLeaveDetails?.data?.map((emp, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{emp.leave_name}</td>
                    <td>{emp.leave_code}</td>
                    <td>{emp.leave_type}</td>
                    <td>{emp.leave_status}</td>
                    <td>{emp.leave_period}</td>
                    <td>{emp.leave_start_date}</td>
                    <td>{emp.leave_end_date}</td>
                    <td>{emp.total_days}</td>
                    <td>{emp.resone}</td>

                    <td>
                      <button
                        className="action-button"
                        onClick={() => {
                          editEmployeeLeaveDetails(emp);
                        }}
                      >
                        <BsThreeDotsVertical />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="detailContainer2">
          <h1
            style={{
              fontWeight: "700",
              fontSize: 25,
              color: "black",
            }}
          >
            Employee
          </h1>
          <div className="searchBar-2">
            <div className="searchBar-wrapper">
              <input
                type="text"
                id="search-query"
                name="query"
                value={query}
                onChange={handleInputChange}
                placeholder="Search..."
                className="searchBar-input"
                autoComplete="current-query"
              />

              <FaSearch className="search-icon" />
            </div>
          </div>

          {filteredCategories.map((emp) => (
            <div key={emp.id}>
              <div
                className={`employee-item ${
                  getEmployeeDetails.findIndex((e) => e.id === emp.id) ===
                  getEmployeeindex
                    ? "selected"
                    : ""
                }`}
                onClick={() => handleRowClick(emp.id)} // Pass the employee ID instead of index
              >
                <FaUser className="user-icon" />
                <div>
                  <h3 className="employee-name">{emp.name}</h3>
                  <h4 className="employee-name1">{emp.employee_code}</h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <AddLeaveStatusModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        user={getEmployeeDetails[getEmployeeindex]}
        // onSave={handleSave}
      />
      <EditLeaveStatusModal
        open={editmodalOpen}
        onClose={() => setEditModalOpen(false)}
        user={getEmployeeDetails[getEmployeeindex]}
        leave={employeeLeave}
      /> */}
    </div>
  );
};

export default AdminLeaves;
