const constants = { 
    EMPLOYEE_DETAILS: 'EMPLOYEE_DETAILS',
    EMPLOYEE_AUTH:'EMPLOYEE_AUTH',
    EMPLOYEE_LEAVE_DETAIL:'EMPLOYEE_LEAVE_DETAIL',
    EMPLOYEE_ALL_DETAILS:'EMPLOYEE_ALL_DETAILS',
    EMPLOYEE_SELECTED_INDEX:'EMPLOYEE_SELECTED_INDEX',
    EMPLOYEE_HOLIDAY:'EMPLOYEE_HOLIDAY',
  
};

export default constants;