import React, { useCallback, useEffect, useState } from "react";
import "./Leaves.css";
import { MdOutlineMailOutline } from "react-icons/md";
import { BiBuildings } from "react-icons/bi";
import { TfiLocationPin } from "react-icons/tfi";
import { LuPhone } from "react-icons/lu";
import { MdLaptopChromebook } from "react-icons/md";
import { useAuth } from "../../Component/Authentication/AuthContext";
import axios from "axios";
import { Api, BaseUrl, ImagePath } from "../../Config/Api";
import { toast } from "react-toastify";
const Leaves = () => {
  const employeeId = sessionStorage.getItem("employeeId");
  const token = sessionStorage.getItem("authToken");
  const { setLoading, logout } = useAuth();
  const [profileData, setProfileData] = useState();
  const fetchEmployeProfile = useCallback(async () => {
    setLoading(true);

    try {
      const responseData = await axios.get(
        `${BaseUrl}${Api.GET_EMPLOYEE_PROFILE}?login_token=${token}&employee_id=${employeeId}`
      );
      if (responseData?.data?.valid === true) {
        if (
          responseData?.data?.mssg ===
          "unothorized token please go to login page"
        ) {
          setLoading(false);
          logout();
        } else {
          setProfileData(responseData?.data?.data);
          setLoading(false);
        }
      } else {
        setLoading(false);
        toast.error(responseData?.data?.mssg[0], {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("API call failed:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [token, setLoading, logout]);

  useEffect(() => {
    fetchEmployeProfile();
  }, []);

  return (
    <div className="mainDivleave1">
      <h1
        style={{ fontWeight: "700", fontSize: 30, color: "black", padding: 15 }}
      >
        Leave Page
      </h1>
      <div className="detailContainer1">
        <div
          style={{
            width: "100%",
            // height: 100,
            padding: 5,
            flexDirection: "row",
            display: "flex",
            background: "#f5f5f5",
            borderWidth: 1,
          }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                paddingLeft: 15,
                paddingTop: 10,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h1
                style={{
                  color: "#155596",
                  fontSize: 25,
                  fontWeight: "700",
                }}
              >
                {profileData?.name}
              </h1>
              <h3
                style={{
                  marginLeft: 10,
                  color: "#155596",
                  fontSize: 15,
                  fontWeight: "400",
                }}
              >
                {profileData?.employee_code}
              </h3>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                paddingLeft: 15,
                paddingTop: 10,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <MdLaptopChromebook color="#155596" size={20} />
              <h1
                style={{
                  fontWeight: "500",
                  fontSize: 15,
                  textAlign: "center",
                  marginLeft: 10,
                  marginTop: 5,
                  color: "#155596",
                }}
              >
                {profileData?.designation}
              </h1>
              <BiBuildings
                color="#155596"
                size={20}
                style={{ marginLeft: 15 }}
              />
              <h1
                style={{
                  fontWeight: "500",
                  fontSize: 15,
                  textAlign: "center",
                  marginLeft: 10,
                  marginTop: 5,
                  color: "#155596",
                }}
              >
                {profileData?.department}
              </h1>
              <TfiLocationPin
                color="#155596"
                size={20}
                style={{ marginLeft: 15 }}
              />
              <h1
                style={{
                  fontWeight: "500",
                  fontSize: 15,
                  textAlign: "center",
                  marginLeft: 10,
                  marginTop: 5,
                  color: "#155596",
                }}
              >
                {profileData?.location}
              </h1>
              <LuPhone color="#155596" size={20} style={{ marginLeft: 15 }} />
              <h1
                style={{
                  fontWeight: "500",
                  fontSize: 15,
                  textAlign: "center",
                  marginLeft: 10,
                  marginTop: 5,
                  color: "#155596",
                }}
              >
                {profileData?.mobile}
              </h1>
              <MdOutlineMailOutline
                color="#155596"
                size={20}
                style={{ marginLeft: 15 }}
              />
              <h1
                style={{
                  fontWeight: "500",
                  fontSize: 15,
                  textAlign: "center",
                  marginLeft: 10,
                  marginTop: 5,
                  color: "#155596",
                }}
              >
                {profileData?.email}
              </h1>
            </div>
          </div>

          <div
            style={{
              width: "90px",
              height: "90px",
              background: "green",
              marginLeft: 10,
              borderRadius: "50%",
              overflow: "hidden",
            }}
          >
            {profileData?.image == null ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  backgroundColor: "gray",
                }}
              >
                <h1
                  style={{
                    fontSize: 30,
                    fontFamily: "cursive",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {profileData?.name.charAt(0).toUpperCase()}
                </h1>
              </div>
            ) : (
              <img
                src={ImagePath + profileData?.image}
                alt="circle"
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                  display: "flex",
                  objectFit: "cover",
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: 100,
            marginTop: 20,
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "85%",
              height: 100,
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "23%",
                height: "100%",
                borderWidth: 1,
                borderColor: "#fac4a7",
                flexDirection: "row",
                display: "flex",
                background: "#fff6f1",
              }}
            >
              <div
                style={{ width: 15, height: "100%", background: "#f97d38" }}
              ></div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: 10,
                }}
              >
                <div>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "700",
                      color: "#2f669e",
                    }}
                  >
                    Total Leaves
                  </h1>

                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "700",
                      color: "#f97d38",
                    }}
                  >
                    {/* {getEmployeeLeaveDetails?.leave_data?.total_leave_entitled} */}
                  </h1>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "23%",
                height: "100%",
                borderWidth: 1,
                borderColor: "#0f8e4d",
                flexDirection: "row",
                display: "flex",
                background: "#cdf2df",
              }}
            >
              <div
                style={{ width: 15, height: "100%", background: "#32b472" }}
              ></div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: 10,
                }}
              >
                <div>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "700",
                      color: "#2f669e",
                    }}
                  >
                    Leave Collected
                  </h1>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "700",
                      color: "#32b472",
                    }}
                  >
                    {/* {getEmployeeLeaveDetails?.leave_data?.leave_collected} */}
                  </h1>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "23%",
                height: "100%",
                borderWidth: 1,
                borderColor: "#e2ac22",
                flexDirection: "row",
                display: "flex",
                background: "#fffaee",
              }}
            >
              <div
                style={{ width: 15, height: "100%", background: "#ffc834" }}
              ></div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: 10,
                }}
              >
                <div>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "700",
                      color: "#2f669e",
                    }}
                  >
                    Paid Leaves
                  </h1>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "700",
                      color: "#ffc834",
                    }}
                  >
                    {/* {getEmployeeLeaveDetails[0]?.paid_leave_taken} */}
                    {/* {getEmployeeLeaveDetails?.leave_data?.paid_leave_taken} */}
                  </h1>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "23%",
                height: "100%",
                borderWidth: 1,
                borderColor: "#d363ed",
                flexDirection: "row",
                display: "flex",
                background: "#ead2ef",
              }}
            >
              <div
                style={{ width: 15, height: "100%", background: "#c280d0" }}
              ></div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: 10,
                }}
              >
                <div>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "700",
                      color: "#2f669e",
                    }}
                  >
                    Unpaid Leaves
                  </h1>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "700",
                      color: "#c280d0",
                    }}
                  >
                    {/* {getEmployeeLeaveDetails?.leave_data?.unpaid_leave_taken} */}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "100%", marginTop: 20 }}>
          <div
            style={{
              marginBottom: 20,

              flexDirection: "row",
              display: "flex",
            }}
          >
            <h1 style={{ color: "#155596", fontWeight: "700", fontSize: 25 }}>
              Leave History
            </h1>
            <div className="form-group2">
              <label htmlFor="experience">Year: </label>

              <select
                name="experience"
                id="experience"
                // value={formData.experience}
                // onChange={handleChange}
                required
              >
                <option value="">Select Year</option>
                <option value="Experience">2024</option>
                <option value="Experience">2025</option>
                <option value="Experience">2026</option>
                <option value="Experience">2027</option>
                <option value="Experience">2028</option>
                <option value="Experience">2024</option>
                <option value="Experience">2029</option>
                <option value="Experience">2030</option>
              </select>
            </div>
            <div className="form-group2">
              <label htmlFor="experience">Month: </label>

              <select
                name="experience"
                id="experience"
                // value={formData.experience}
                // onChange={handleChange}
          
              >
                <option value="">Select Month</option>
                <option value="Experience">January</option>
                <option value="Experience">February</option>
                <option value="Experience">March</option>
                <option value="Experience">April</option>
                <option value="Experience">May</option>
                <option value="Experience">June</option>
                <option value="Experience">July</option>
                <option value="Experience">August</option>
                <option value="Experience">September</option>
                <option value="Experience">October</option>
                <option value="Experience">November</option>
                <option value="Experience">December</option>
              </select>
            </div>
          </div>
          <table className="employee-table1">
            <thead>
              <tr>
                <th>S.N</th>
                <th>Leave leave_name</th>
                <th>Leave Code</th>
                <th>Leave Type</th>
                <th>Leave Status</th>
                <th>Leave Unit</th>
                <th>From Date</th>
                <th>To Date</th>
                <th>Total Days</th>
                <th>Leave Reason</th>
              </tr>
            </thead>

            {/* <tbody>
                {getEmployeeLeaveDetails?.data?.map((emp, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{emp.leave_name}</td>
                    <td>{emp.leave_code}</td>
                    <td>{emp.leave_type}</td>
                    <td>{emp.leave_status}</td>
                    <td>{emp.leave_period}</td>
                    <td>{emp.leave_start_date}</td>
                    <td>{emp.leave_end_date}</td>
                    <td>{emp.total_days}</td>
                    <td>{emp.resone}</td>

                    
                  </tr>
                ))}
              </tbody> */}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Leaves;
